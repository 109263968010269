import React, {useState, useEffect} from 'react';

export const TemplateFooter = () => (
    <table
        cellPadding={0}
        cellSpacing={0}
        role="presentation"
        style={{width: "100%"}}
    >
        <tbody>
        <tr>
            <td
                style={{
                    direction: "ltr",
                    fontSize: 0,
                    padding: "0px 0px 0px 0px",
                    textAlign: "center"
                }}
            >
                <div
                    className="mj-column-per-100 mj-outlook-group-fix"
                    style={{
                        fontSize: 0,
                        textAlign: "center",
                        direction: "ltr",
                        display: "inline-block",
                        verticalAlign: "top",
                        width: "100%"
                    }}
                >
                    <table
                        cellPadding={0}
                        cellSpacing={0}
                        role="presentation"
                        style={{verticalAlign: "top"}}
                        width="100%"
                    >
                        <tbody>
                        <tr>
                            <td
                                style={{
                                    fontSize: 0,
                                    padding: "10px 0px 0px 0px",
                                    wordBreak: "break-word"
                                }}
                            >
                                <p
                                    style={{
                                        borderTop: "solid 1px #E6E6E6",
                                        fontSize: 1,
                                        margin: "0px auto",
                                        width: "100%"
                                    }}
                                ></p>
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    fontSize: 0,
                                    padding: "3px 25px 3px 25px",
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                    wordBreak: "break-word"
                                }}
                            >
                                <div
                                    style={{
                                        fontFamily: "Arial, sans-serif",
                                        fontSize: 13,
                                        letterSpacing: "normal",
                                        lineHeight: 1,
                                        textAlign: "center",
                                        color: "#000000"
                                    }}
                                >
                                    <p
                                        className="text-build-content"
                                        style={{
                                            textAlign: "center",
                                            margin: "10px 0",
                                            marginTop: 10,
                                            marginBottom: 10
                                        }}
                                    >
                      <span style={{color: "#777777", fontSize: 16}}>
                        Keep Dreaming
                      </span>
                                    </p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    fontSize: 0,
                                    padding: "0px 0px 0px 0px",
                                    wordBreak: "break-word"
                                }}
                            >
                                <p
                                    style={{
                                        borderTop: "solid 20px #E6E6E6",
                                        fontSize: 1,
                                        margin: "0px auto",
                                        width: "100%"
                                    }}
                                ></p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </td>
        </tr>
        </tbody>
    </table>
)
