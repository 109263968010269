import React, {useState, useEffect} from 'react';
import 'styles/css/joinTeam.css'
import gql from 'graphql-tag';
import {useTeamNameByCodeQuery} from "../../types/gqlReactTypings.generated.d";
import {TemplateHeader} from "./components/TemplateHeader";
import {TemplateFooter} from "./components/TemplateFooter";

gql`
    query TeamNameByCode($code: String!) {
        teamNameByCode(code: $code) {
            name
        }
    }
`

export const JoinTeam = () => {
    const errorString = 'The code is wrong!';
    const queryParameters = new URLSearchParams(window.location.search)
    const code = queryParameters.get("code");
    const [errorMessage, setErrorMessage] = useState(!code ? errorString : '');
    const [teamCode, setTeamCode] = useState(code ? code : '');
    const [buttonText, setButtonText] = useState('Tap to copy');
    const [teamName, setTeamName] = useState('');

    const {data, loading, error} = useTeamNameByCodeQuery({variables: {code: teamCode}});
    useEffect(() => {
        if (!loading && !error) {
            if (data && data.teamNameByCode) {
                setTeamName(data.teamNameByCode.name || '');
            }
        }
        if (!loading && error) {
            setErrorMessage(errorString);
        }
    }, [data, loading, error]);
    const copyCode = () => {
        navigator.clipboard.writeText(teamCode ? teamCode : '').then(function () {
            setButtonText('Team code copied!');
        });
    }

    return (
        <div className="container">
            {/*<div className="header">*/}
            {/*    <a href="https://www.ultrainsports.com/" target="_blank">*/}
            {/*        <img*/}
            {/*            src="https://static.wixstatic.com/media/8c0d9b_421b49b10c274d31aba09ddcee23b485~mv2.png/v1/fill/w_217,h_48,al_c,q_85,enc_auto/Group%20123.png"*/}
            {/*            alt="" width="217" height="48"/>*/}
            {/*    </a>*/}
            {/*</div>*/}
            <TemplateHeader/>
            <div className="content">

                <div className="card">
                    <div className="card-header error-message"
                         style={{display: errorMessage ? 'block' : 'none'}}>&#128542;</div>
                    <div className="card-header" style={{display: !errorMessage ? 'block' : 'none'}}>
                        <div className="team-name">
                            <div>Your team: {teamName}</div>
                        </div>
                    </div>
                    <div className="card-content error-message"
                         style={{display: errorMessage ? 'block' : 'none'}}>{errorMessage}</div>
                    <div className="card-content" style={{display: !errorMessage ? 'block' : 'none'}}>
                        <div className="instructions">
                            To join your team, download Ultrain: Sport Team Manager on the App Store or Google Play.
                            Open app,
                            and
                            enter the team code
                            below.
                        </div>
                        <div className="code" onClick={copyCode}>
                            <div id="copy-code" className=" copy-button">{buttonText}</div>
                            <div id="code">{teamCode}</div>
                        </div>
                        <div className="app-buttons">
                            <a href="https://apps.apple.com/gb/app/ultrain/id6449619135" target="_blank"
                               className="app-button" id="app-store">
                                <img
                                    src="https://static.wixstatic.com/media/8c0d9b_97529cd84e9c44639d7f360d29aa5231~mv2.png/v1/fill/w_127,h_41,al_c,q_85,enc_auto/png-transparent-app-store-logo-iphone-ap.png"
                                    alt="" width="127" height="41"/>
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.ultrainapp.Ultrain"
                               target="_blank" className="app-button" id="google-play">
                                <img
                                    src="https://static.wixstatic.com/media/8c0d9b_37e6adb5bb304d2886fa8f64b0091289~mv2.png/v1/fill/w_139,h_41,al_c,lg_1,q_85,enc_auto/8c0d9b_37e6adb5bb304d2886fa8f64b0091289~mv2.png"
                                    alt="" width="139" height="41"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <TemplateFooter/>
            {/*<div className="footer">*/}
            {/*    <div style={{margin: '38px 0px 28px 0'}}>*/}
            {/*        <a href="https://www.ultrainsports.com/" target="_blank">*/}
            {/*            <img*/}
            {/*                src="https://static.wixstatic.com/media/8c0d9b_e6a688b7c23149d7a81050dd223929cf~mv2.png/v1/fill/w_269,h_60,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%20380.png"*/}
            {/*                alt="Ultrain Sports"*/}
            {/*                style={{width: '269px', height: '60px', 'objectFit': 'cover', 'objectPosition': '50% 50%'}}*/}
            {/*                width="269" height="60"/>*/}
            {/*        </a>*/}
            {/*    </div>*/}
            {/*    <div style={{*/}
            {/*        gap: '21px',*/}
            {/*        display: 'flex',*/}
            {/*        flexDirection: 'row',*/}
            {/*        flexWrap: 'wrap',*/}
            {/*        justifyContent: 'center',*/}
            {/*        marginBottom: 28*/}
            {/*    }}>*/}
            {/*        <a href="https://www.facebook.com/Ultrainsports/" target="_blank">*/}
            {/*            <img*/}
            {/*                src="https://static.wixstatic.com/media/8c0d9b_2838907915974918b2794a0d21a71827~mv2.png/v1/fill/w_30,h_30,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/facebook%20(13).png"*/}
            {/*                alt="facebook"*/}
            {/*                style={{width: '30px', height: '30px', 'objectFit': 'cover', 'objectPosition': '50% 50%'}}*/}
            {/*                width="30" height="30"/>*/}
            {/*        </a>*/}
            {/*        <a href="https://www.instagram.com/ultrainsports/" target="_blank">*/}
            {/*            <img*/}
            {/*                src="https://static.wixstatic.com/media/8c0d9b_bab53ba5d49f478ca102b5356cc1850d~mv2.png/v1/fill/w_30,h_30,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/instagram%20(11).png"*/}
            {/*                alt="instagram"*/}
            {/*                style={{width: '30px', height: '30px', 'objectFit': 'cover', 'objectPosition': '50% 50%'}}*/}
            {/*                width="30" height="30"/>*/}
            {/*        </a>*/}
            {/*        <span>*/}
            {/*            <img*/}
            {/*                src="https://static.wixstatic.com/media/8c0d9b_283bd24f57254a1da1bbd907af90d748~mv2.png/v1/fill/w_30,h_30,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/twitter%20(6).png"*/}
            {/*                alt="twitter"*/}
            {/*                style={{width: '30px', height: '30px', 'objectFit': 'cover', 'objectPosition': '50% 50%'}}*/}
            {/*                width="30" height="30"/>*/}
            {/*        </span>*/}
            {/*        <a href="https://www.youtube.com/channel/UCn0HTdAh_nVCg2rnkS2bC6w" target="_blank">*/}
            {/*            <img*/}
            {/*                src="https://static.wixstatic.com/media/8c0d9b_7855582c73ee4c939a7b68edad965d64~mv2.png/v1/fill/w_30,h_30,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/youtube%20(8).png"*/}
            {/*                alt="youtube"*/}
            {/*                style={{width: '30px', height: '30px', 'objectFit': 'cover', 'objectPosition': '50% 50%'}}*/}
            {/*                width="30" height="30"/>*/}
            {/*        </a>*/}
            {/*    </div>*/}
            {/*    <div style={{*/}
            {/*        fontFamily: 'wf_2',*/}
            {/*        fontSize: '14px',*/}
            {/*        textAlign: 'center',*/}
            {/*        color: '#585858',*/}
            {/*    }}>*/}
            {/*        © All rights reserved | 2023 ULTRAIN SPORTS*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}
